@font-face {
  font-family: 'Noto Sans';
  src: url('font/NotoSansKR-Black900.woff2') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('font/NotoSansKR-Bold700.woff2') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('font/NotoSansKR-Medium500.woff2') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('font/NotoSansKR-Regular400.woff2') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('font/NotoSansKR-Light300.woff2') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('font/NotoSansKR-Thin100.woff2') format('truetype');
  font-weight: 100;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
