.MainContainer {
  min-height: 100vh;
  min-width: 100%;
  .Section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100%;
    flex-direction: column;
    * {
      z-index: 1;
    }
    .LogoBackground {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
    }

    .ContentsWrapper {
      min-width: 80%;
      padding: 24px 12px;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .Footer {
    padding: 24px 0 24px 36px;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
